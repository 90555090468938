import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { TrustpilotReview } from '@talentinc/gatsby-source-trustpilot-widget'
import useIntersection from 'react-use/lib/useIntersection'
import SvgTrustPilotFiveStars from '../../images/trustpilot-five-stars.inline.svg'
import { ReviewCategory } from '../../types/review'
import { useTrustPilotTaggedReviews } from '../../hooks/useTrustPilotReviews'
import CallToAction from '../CallToAction'
import { CTA, CTAVariants } from '../../types/cta'
import useBrand from '../../hooks/useBrand'

interface Props {
  category: ReviewCategory
  setActiveGroup: Dispatch<SetStateAction<string>>
}

const ReviewGroup: React.FC<Props> = ({ category, setActiveGroup }) => {
  const { classes, cx } = useStyles()
  const intersectionRef = React.useRef(null)
  const intersection = useIntersection(intersectionRef, {
    root: null,
    // Can only use px here
    rootMargin: '700px',
    threshold: 0.77,
  })
  const brand = useBrand()
  useEffect(() => {
    if (intersection && intersection?.intersectionRatio >= 0.77) {
      setActiveGroup(category.title)
    }
  }, [intersection, category.title, setActiveGroup])

  const reviews = useTrustPilotTaggedReviews(category.tag, brand.name)
  const combinedReviewCTA = [
    ...reviews.slice(0, 5),
    category.cta,
    ...reviews.slice(5),
  ]

  return (
    <Box
      className={cx(classes.reviewList)}
      //@ts-ignore
      ref={intersectionRef}
    >
      {combinedReviewCTA.map((review, index) => {
        return <ReviewSectionLink {...review} key={index} />
      })}
    </Box>
  )
}

export const ReviewSectionLink: React.FC<TrustpilotReview | CTA> = (review) => {
  const { classes } = useStyles()
  switch (review.__typename) {
    case 'ContentfulCallToAction':
      return (
        <Box className={classes.reviewCTA} key={review.contentful_id}>
          <CallToAction
            cta={{
              ...review,
              variant: CTAVariants.CornerImage,
              classes: {
                header: classes.ctaHeader,
                root: classes.ctaRoot,
                image: classes.ctaImg,
                actions: classes.ctaActions,
                text: classes.ctaText,
              },
            }}
          />
        </Box>
      )
    default:
      return (
        <Box
          className={classes.reviewCard}
          key={review.title}
          itemScope
          itemType="https://schema.org/Review"
        >
          <Box
            itemProp="reviewRating"
            itemScope
            itemType="https://schema.org/Rating"
          >
            <meta itemProp="worstRating" content="1" />
            <meta itemProp="ratingValue" content={review.stars.toString()} />
            <meta itemProp="bestRating" content="5" />
            <SvgTrustPilotFiveStars
              alt="Five Stars"
              className={classes.stars}
            />
          </Box>
          <meta itemProp="datePublished" content={review.createdAt} />
          <Typography className={classes.title} variant="body1" itemProp="name">
            {review.title}
          </Typography>
          <Typography
            className={classes.text}
            variant={'body2'}
            itemProp="reviewBody"
          >
            {review.text} — {review.consumer.displayName}
          </Typography>
        </Box>
      )
  }
}

const useStyles = makeStyles()((theme) => ({
  reviewList: {
    padding: '0 6% 4.125rem 6%',
    marginTop: '1rem',
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('lg')]: {
      padding: '4rem 6% 3.5rem 6%',
    },
    [theme.breakpoints.down('md')]: {
      margin: 'auto',
      padding: '0 6% 3.5rem 6%',
    },
    '@media (min-width: 1456px)': {
      padding: '0 calc(100% - 40rem) 4.125rem 5.5rem',
    },
  },

  reviewCard: {
    '&:not(first-child)': {
      paddingTop: '4rem',
    },

    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  reviewCTA: {
    maxWidth: '33.9375rem',
    paddingTop: '4.125rem',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  stars: {
    color: theme.colors.orange['a100'],
  },
  title: {
    fontWeight: 'bold',
    padding: '.8rem 0 .65rem 0',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
      lineHeight: 1.312,
    },
  },
  text: {
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
  },
  content: {
    paddingBottom: '2rem',
    maxWidth: '37.25rem',
    '& > a:nth-child(2)': {
      marginLeft: '1rem',
      [theme.breakpoints.down('md')]: {
        margin: '1rem 0',
      },
    },
    '& > button:nth-child(2)': {
      marginLeft: '1rem',
      [theme.breakpoints.down('md')]: {
        margin: '1rem 0',
      },
    },
    '& > a': {
      [theme.breakpoints.down('md')]: {
        marginLeft: '0',
        width: '100%',
      },
    },
    '& > button': {
      [theme.breakpoints.down('md')]: {
        marginLeft: '0',
        width: '100%',
      },
    },
  },
  bg: {
    background: theme.palette.background.paper,
  },
  ctaHeader: {
    margin: '0 0 1.1875rem 0',
    '& > *': {
      fontSize: '1.5rem',
    },
  },
  ctaImg: {
    maxWidth: '9.6875rem',
    margin: '0 -3.1em -9.7em 5%',
    [theme.breakpoints.down('lg')]: {
      display: 'none !important',
    },
  },
  ctaRoot: {
    color: theme.palette.primary.dark,
    background: theme.palette.background.paper,
    minHeight: 'unset',
    padding: '2.5rem 1.5rem 1.875rem 1.5rem',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  ctaActions: {
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      '& a': {
        width: '100%',
        '&:not(:first-of-type)': {
          marginLeft: 0,
          marginTop: '.5rem',
          display: 'block',
        },
      },
    },
  },
  ctaText: {
    margin: '0 0 1.1875rem 0',
  },
}))

export default ReviewGroup
