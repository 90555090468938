import React, { useState } from 'react'
import { Box, Typography, useScrollTrigger } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import RichText from '../RichText'
import useTrustPilot from '../../hooks/useTrustPilot'
import { Trans } from 'gatsby-plugin-react-i18next'
import SvgTrustPilotFiveStars from '../../images/trustpilot-five-stars.inline.svg'
import { ReviewPage as ContentfulReviewPage } from '../../types/review'
import { BLOCKS } from '@contentful/rich-text-types'
import kebabCase from 'lodash/kebabCase'
import ReviewGroup from './ReviewGroup'
import ReviewNavbar from './ReviewNavbar'
import XRayWrapper from '../XRay/XRayWrapper'

interface ReviewPageProps {
  reviewPage: ContentfulReviewPage
}

const ReviewPage: React.FC<ReviewPageProps> = ({ reviewPage }) => {
  const { classes, cx } = useStyles()
  const trustPilotCount = useTrustPilot()
  const [activeGroup, setActiveGroup] = useState('')
  const fiveStars = trustPilotCount?.businessUnit?.numberOfReviews?.fiveStars
    ? trustPilotCount.businessUnit?.numberOfReviews.fiveStars
    : 800
  const roundedFiveStars = Math.floor(fiveStars / 50) * 50

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 420,
  })

  return (
    <XRayWrapper id={reviewPage.contentful_id}>
      <Box className={classes.box} component="main">
        <Box className={classes.sideBar}>
          <ReviewNavbar
            categories={reviewPage.reviewCategories}
            activeGroup={activeGroup}
            setActiveGroup={setActiveGroup}
          />
        </Box>
        <Box
          className={cx({
            [classes.mobileAppBar]: true,
            [classes.appBarScrolled]: trigger,
          })}
        >
          <ReviewNavbar
            categories={reviewPage.reviewCategories}
            activeGroup={activeGroup}
            setActiveGroup={setActiveGroup}
          />
        </Box>
        <Box className={classes.review}>
          <Box className={classes.reviewHeader}>
            <Box className={classes.reviewHeaderInner}>
              <SvgTrustPilotFiveStars
                alt="5 Star Review"
                className={classes.trustPilotStars}
              />
              <Typography variant={'h1'} className={classes.trustPilotReviews}>
                <Trans
                  i18nKey="reviewPage.fiveStarsReviews"
                  values={{
                    reviews: roundedFiveStars,
                  }}
                />
              </Typography>
              <RichText
                rawBody={reviewPage.headerBody.raw}
                references={reviewPage.headerBody.references}
                options={{
                  props: {
                    [BLOCKS.HEADING_2]: {
                      className: classes.header,
                    },
                    [BLOCKS.PARAGRAPH]: {
                      className: classes.content,
                    },
                  },
                }}
              />
            </Box>
          </Box>
          <Box>
            {reviewPage.reviewCategories.map((reviewCategory) => (
              <Box
                key={reviewCategory.contentful_id}
                id={`review-${kebabCase(reviewCategory.title)}`}
                data-contentful-id={reviewCategory.contentful_id}
              >
                <Box className={classes.reviewSection}>
                  <Typography variant={'h2'} className={classes.title}>
                    {reviewCategory.title}
                  </Typography>
                  <Box>
                    <RichText
                      rawBody={reviewCategory.body.raw}
                      references={reviewCategory.body.references}
                      options={{
                        props: {
                          [BLOCKS.PARAGRAPH]: {
                            className: classes.content,
                          },
                        },
                      }}
                    />
                  </Box>
                </Box>
                <ReviewGroup
                  category={reviewCategory}
                  key={reviewCategory.contentful_id}
                  setActiveGroup={setActiveGroup}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </XRayWrapper>
  )
}

const useStyles = makeStyles()((theme) => ({
  box: {
    maxWidth: '100%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      justifyContent: 'space-around',
    },
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      flexDirection: 'column',
    },
  },
  reviewSection: {
    textAlign: 'center',
    margin: 'auto',
    display: 'none',
    color: 'white',
    backgroundColor: theme.palette.primary.dark,
    [theme.breakpoints.down('md')]: {
      display: 'block',
      maxWidth: '100%',
      padding: '2.125rem 1.1875rem .5rem 1.1875rem',
    },
  },
  review: {
    width: '100%',
    padding: '0',
    backgroundColor: 'white',
  },
  reviewHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: '7rem',
    paddingBottom: '3.4rem',
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('md')]: {
      paddingTop: '0',
      paddingBottom: '0',
      minHeight: '0',
    },
  },
  reviewHeaderInner: {
    maxWidth: '100%',
    padding: '0 6%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      margin: 'auto',
      padding: '3.375rem 6%',
    },
    '@media (min-width: 1456px)': {
      padding: '0 calc(100% - 40rem) 0 5.5rem',
    },
  },
  trustPilotCount: {
    paddingLeft: '0.75rem',
  },
  trustPilotStars: {
    width: '9.5rem',
    height: '2.5rem',
    color: theme.colors.orange['a100'],
  },
  trustPilotReviews: {
    padding: '.75rem 0',
    [theme.breakpoints.down('md')]: {
      padding: '.75rem 3rem',
    },
  },
  scroll: {
    fontSize: '1rem',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  header: {
    paddingBottom: '1rem',
  },
  title: {
    paddingBottom: '0.75rem',
  },
  content: {
    paddingBottom: '2rem',
    maxWidth: '37.25rem',
    '& > a:nth-child(2)': {
      marginLeft: '1rem',
      [theme.breakpoints.down('md')]: {
        margin: '1rem 0 0 0',
      },
    },
    '& > button:nth-child(2)': {
      marginLeft: '1rem',
      [theme.breakpoints.down('md')]: {
        margin: '1rem 0 0 0',
      },
    },
    '& > a': {
      [theme.breakpoints.down('md')]: {
        marginLeft: '0',
        width: '100%',
      },
    },
    '& > button': {
      [theme.breakpoints.down('md')]: {
        marginLeft: '0',
        width: '100%',
      },
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: '1.5625rem',
      maxWidth: '100%',
    },
  },
  sideBar: {
    position: 'sticky',
    display: 'flex',
    flexDirection: 'column',
    top: '4.19125rem',
    minHeight: '100vh',
    color: 'white',
    width: '100%',
    paddingBottom: '4.125rem',
    backgroundColor: theme.palette.primary.dark,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  mobileAppBar: {
    display: 'none',
    position: 'sticky',
    flexDirection: 'column',
    top: '4.19125rem',
    color: 'white',
    width: '100%',
    backgroundColor: theme.palette.primary.dark,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 0,
      minHeight: 0,
      zIndex: '1000',
    },
  },
  appBarScrolled: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
  },
}))

export default ReviewPage
