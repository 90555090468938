import React, { Dispatch, SetStateAction, useState } from 'react'
import {
  Box,
  Typography,
  IconButton,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from '@mui/material'
import { makeStyles, withStyles } from 'tss-react/mui'
import { Close, Menu } from '@mui/icons-material'
import { ReviewCategory } from '../../types/review'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import JumpLink from '../Link/JumpLink'
import kebabCase from 'lodash/kebabCase'
import RichText from '../RichText'
import { BLOCKS } from '@contentful/rich-text-types'
import useLockBodyScroll from 'react-use/lib/useLockBodyScroll'

interface Props {
  categories: ReviewCategory[]
  activeGroup: string
  setActiveGroup: Dispatch<SetStateAction<string>>
}

const ReviewNavbar: React.FC<Props> = ({
  categories,
  activeGroup,
  setActiveGroup,
}) => {
  const { t } = useTranslation()
  const { classes, cx } = useStyles()
  const [open, setOpen] = useState(false)

  const handleChange =
    (panel: React.SetStateAction<string>) => (newExpanded: boolean) => {
      // @ts-ignore
      setActiveGroup(newExpanded ? panel : false)
    }
  useLockBodyScroll(open)

  return (
    <Box className={classes.sideBarInner}>
      <Box className={classes.sideBarInnerDesktop}>
        <Typography className={classes.categorySubtitle}>
          {t('reviewPage.searchByCategory')}
        </Typography>
        {categories.map((category) => (
          <Accordion
            key={category.contentful_id}
            data-contentful-id={category.contentful_id}
            expanded={activeGroup === category.title}
            // @ts-ignore
            onChange={handleChange(category.title)}
          >
            <AccordionSummary>
              <JumpLink
                className={cx({
                  [classes.categoryTitle]: true,
                  [classes.active]: activeGroup === category.title,
                })}
                hash={`review-${kebabCase(category.title)}`}
                key={`review-nav-${category.contentful_id}`}
              >
                <Typography variant={'h2'}>{category.title}</Typography>
              </JumpLink>
            </AccordionSummary>
            <AccordionDetails>
              <div itemProp="text">
                <RichText
                  rawBody={category.body.raw}
                  references={category.body.references}
                  options={{
                    props: {
                      [BLOCKS.PARAGRAPH]: {
                        className: cx(classes.text),
                      },
                    },
                  }}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      <Box>
        <Box className={classes.sideBarInnerMobile}>
          <Box className={cx(classes.navigationRight)}>
            <Typography className={classes.categorySubtitle}>
              {t('reviewPage.chooseACategory')}
            </Typography>
            <IconButton
              aria-label={open ? 'close' : 'menu'}
              className={classes.iconButton}
              color="inherit"
              onClick={() => setOpen(!open)}
              size="large"
            >
              {open ? <Close /> : <Menu />}
            </IconButton>
          </Box>
          {open && (
            <Box onClick={() => setOpen(!open)}>
              {categories.map((category) => (
                <JumpLink
                  className={classes.categoryTitle}
                  hash={`review-${kebabCase(category.title)}`}
                  key={`review-nav-${category.contentful_id}`}
                >
                  <Typography variant={'h3'}>{category.title}</Typography>
                </JumpLink>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

const Accordion = withStyles(MuiAccordion, () => ({
  root: {
    backgroundColor: 'transparent',
    '&:last-child': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  expanded: {
    '&&': {
      margin: 0,
    },
  },
}))

const AccordionSummary = withStyles(MuiAccordionSummary, () => ({
  root: {
    padding: '1rem 0',
    '&.Mui-expanded': {
      paddingBottom: 0,
    },
    '&:focus': {
      outline: 0,
    },
  },
  content: {
    marginTop: 0,
    marginBottom: 0,
  },
  expanded: {
    '&&': {
      minHeight: 0,
      margin: 0,
    },
  },
}))

const AccordionDetails = withStyles(MuiAccordionDetails, () => ({
  root: {
    paddingLeft: 0,
    '& p + p': {
      marginTop: '1.5rem',
    },
  },
}))

const useStyles = makeStyles()((theme) => ({
  sideBarInner: {
    padding: '0 6% 0 6%',
    background: 'transparent',
    [theme.breakpoints.down('lg')]: {
      padding: '0 6%',
    },
    '@media (min-width: 1456px)': {
      padding: '0 5.5rem 0 calc(100% - 40rem)',
    },
  },
  sideBarInnerDesktop: {
    paddingTop: '11.25rem',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  sideBarInnerMobile: {
    display: 'none',
    marginTop: '1.437rem',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      paddingBottom: '1.125rem',
    },
  },
  categorySubtitle: {
    textTransform: 'uppercase',
    fontSize: '0.875rem',
    letterSpacing: '0.9px',
    fontWeight: 'bold',
  },
  active: {
    '&&': {
      opacity: 1,
    },
  },
  categoryTitle: {
    color: 'white',
    display: 'block',
    opacity: 0.5,
    padding: '0',
    '&:hover, &:active, &:focus': {
      color: 'white',
      opacity: 1,
    },
    [theme.breakpoints.down('md')]: {
      margin: '1.6874rem 1.175rem 2.5rem 1.2rem',
    },
  },
  iconButton: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'unset',
      padding: '0',
    },
  },
  navigationRight: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    margin: 'unset',
    width: '100%',

    '& > p': { flex: 1 },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  text: {
    maxWidth: '100%',
    color: 'white',
    '& > a:nth-child(2)': {
      marginLeft: '1rem',
      [theme.breakpoints.down('md')]: {
        margin: '1rem 0',
      },
    },
    '& > button:nth-child(2)': {
      marginLeft: '1rem',
      [theme.breakpoints.down('md')]: {
        margin: '1rem 0',
      },
    },
    '& > a': {
      [theme.breakpoints.down('md')]: {
        marginLeft: '0',
        width: '100%',
      },
    },
    '& > button': {
      [theme.breakpoints.down('md')]: {
        marginLeft: '0',
        width: '100%',
      },
    },
  },
}))

export default ReviewNavbar
